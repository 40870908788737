* {
  box-sizing: border-box;
}

.write_form_wrapper {
  width: 400px;
  height: 100%;
}

.write_head {
  height: 56px;
  padding: 0 20px;
  background-color: #f4f4f4;
  font-size: 20px;
  line-height: 56px;
}

/* 작성 drawer */
/* 업무일지 */
.write_list_wrapper {
  padding: 20px 24px;
  font-size: 16px;
  min-width: 350px;
  position: relative;
}

.dialog>button {
  width: 73px;
  height: 34px;
  border-radius: 6px;
}

.div_icon {
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0, 0.6);
  width: 100%;
}

.div_button_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 15px;
}

.work_bottom_search {
  display: flex;
}

.reset_wrapper {
  position: relative;
  width: 59px;
  height: 34px;
}

.div_reset_icon {
  position: absolute;
  bottom: 0px;
  display: flex;
  width: 59px;
  height: 18px;
  align-items: center;
}

.write_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.write_content>div {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.write_content>div>div.div_icon {
  width: 200px;
}

.write_content>div>label {
  width: 196px;
}

.write_content>.write_date {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 12px;
  border-bottom: 1px solid #cccccc;
}

.write_content>.write_date>.date_time_input__box {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 5px;
  min-width: 265px;
}

.write_content>.write_date>.date_time_input__box>input {
  height: 34px;
  color: "#262626";
  width: fit-content;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 14px;
}

.write_content>div>span {
  width: 60%;
  min-width: 216px;
  /* height: 100%; */
  line-height: 34px;
  padding: 0 10px;
}

.write_content>div select {
  width: 60%;
  min-width: 216px;
  height: 100%;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 14px;
}

.write_content>.work_process {
  margin-top: 20px;
  display: flex;
}

.work_option {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.work_option>div {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.work_option>.write_date {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  /* gap: 15px; */
  height: 50px;
  margin-bottom: 12px;
  border-bottom: 1px solid #cccccc;
}

.work_option>.write_date>input {
  height: 34px;
  width: fit-content;
  color: "#262626";
  width: fit-content;
}

.work_option>div>span {
  width: 60%;
  min-width: 216px;
  /* height: 100%; */
  line-height: 34px;
  padding: 0 10px;
}

.work_option>div>text {
  width: 60%;
  min-width: 216px;
  line-height: 34px;
  padding: 0 10px;
}

.work_option>div select {
  width: 60%;
  min-width: 216px;
  height: 100%;
  /* height: 36px; */
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 14px;
}

.work_option>.work_process {
  margin-top: 20px;
  display: flex;
}

.work_option>div>div.div_icon {
  width: 200px;
}

.work_option>.textarea_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
}

.work_option>.textarea_wrapper>.div_icon {
  height: 40px;
}

.textarea_wrapper>textarea {
  width: 100%;
  height: 140px;
  padding: 16px 14px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  resize: none;
  box-sizing: border-box;
}

.auto_company {
  position: relative;
}

.auto_company input[type="text"] {
  padding-right: 30px;
  background-image: url("../assets/search.svg");
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 20px;
}

.auto_company>input {
  width: 216px;
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 6px;
}

.auto_company>div {
  position: absolute;
  border: 1px solid #ccc;
  top: 34px;
  width: 216px;
  height: 120px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 999;
  cursor: pointer;
  font-size: 14px;
  color: #000;
  border-radius: 6px;
  box-shadow: 2px 2px 8px rgb(0, 0, 0, 0.2);
  overflow-y: scroll;
}

.write_cons_sum>text {
  width: 216px;
  color: #000;
}

.auto_options>div:hover {
  background-color: #cedbf776;
}

.write_content>.textarea_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
}

.write_content>.textarea_wrapper>.div_icon {
  height: 40px;
}

.write_content>.textarea_wrapper>textarea {
  width: 100%;
  height: 140px;
  padding: 16px 14px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  resize: none;
  box-sizing: border-box;
}

.write_content>.admin_box>div:last-child {
  width: 60%;
  height: 100%;
  min-width: 216px;
  vertical-align: baseline;
}

.write_content>.admin_box div input[type="checkbox"] {
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 6px;
  accent-color: var(--highlight-c);
}

/* .write_content > .admin_box div input[type="checkbox"]:checked {
  height: 30px;
  width: 30px;
  color: var(--highlight-c);
} */

.write_content>div>input {
  height: 100%;
  width: 60%;
  min-width: 216px;
  font-size: 15px;
  padding: 0 10px;
  border: 1px solid #cccccc;
  border-radius: 6px;
}

.members_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.select_members {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.select_members>div>button {
  width: 32px;
  height: 32px;
  margin-left: 4px;
  border-style: none;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #999;
  position: relative;
}

.select_members>.plusBtn {
  height: 21px;
  border: none;
  border-radius: 6px;
  background-color: #cedbf776;
}

.select_members>div>select {
  height: 34px;
  width: 155px;
  border-radius: 6px;
  border: 1px solid #ccc;
  color: #000;
}

/* .select_members .removeBtn path:hover {
  fill: #cedbf776;
  fill: #4e20a5;
} */

.select_members .plusBtn:hover {
  background-color: #023373;
  fill: #fff;
}

/* 영업일지 */
.sales_list_wrapper {
  padding: 8px 24px;
  width: 400px;
  font-size: 16px;
}

.sales_list_wrapper .sales_detail {
  display: flex;
  flex-direction: column;
}

.sales_detail>div {
  line-height: 34px;
}

.sales_detail .user_info {
  display: flex;
}

.sales_detail .user_info>div {
  width: 130px;
}

.sales_content {
  margin-top: 10px;
}

.sales_content textarea {
  height: 140px;
  width: 100%;
  padding: 16px 14px;
  border-radius: 6px;
  border: 1px solid #ccc;
  resize: none;
  margin-bottom: 10px;
  color: #000;
}

.sales_bottom {
  height: 40px;
  display: flex;
  justify-content: end;
  gap: 10px;
}

.sales_bottom button {
  width: 100px;
  padding: 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.sales_bottom button:nth-child(2) {
  width: 150px;
  background-color: #104573;
  cursor: pointer;
}

/* 차량일지 */
.carlog_option {
  margin-top: 20px;
  margin-bottom: 20px;
}

.carlog_option>div:not(:last-child) {
  margin-bottom: 12px;
}

.carlog_option>div>div {
  height: 34px;
}

.carlog_option>div:not(:last-child)>div:not(.log_note) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carlog_option>div>div:not(:last-child) {
  margin-bottom: 4px;
}

.carlog_option>div>div>select {
  width: 60%;
  min-width: 216px;
  height: 34px;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #cccccc;
}

.carlog_option>div>div>input {
  width: 60%;
  min-width: 216px;
  height: 34px;
  padding: 12px 10px;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #cccccc;
}

.carlog_option>div>div>span {
  width: 60%;
  min-width: 216px;
  height: 34px;
  display: flex;
  align-items: center;
  color: #000;
}

.log_note>textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;
  padding: 10px 14px;
  font-size: 16px;
  resize: none;
  border-radius: 6px;
  border: 1px solid #cccccc;
}

.phone_input {
  width: 60%;
  min-width: 216px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* 번호입력 form */
.phone_input>input {
  padding: 12px 10px;
  width: 30%;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #cccccc;
  text-align: center;
  font-size: 15px;
}

/* 신규 업체 등록 */
.company_write_wrapper {
  padding: 20px 24px;
  position: relative;
  /* margin-bottom: 20px; */
}

.company_write_wrapper>div:not(.company_fax) {
  margin-bottom: 8px;
}

.company_write_wrapper>div:not(.company_address) {
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company_write_wrapper>div>input {
  border: 1px solid #cccccc;
  border-radius: 6px;
  height: 34px;
  padding: 12px 10px;
  width: 60%;
  min-width: 230px;
}

.region_select {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  min-width: 230px;
}

.region_select>select {
  flex: 1;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.region_select>select:not(:last-child) {
  margin-right: 3px;
}

.company_address {
  display: flex;
  justify-content: flex-end;
}

.company_address>input {
  width: 332px;
  height: 34px;
  /* display: flex; */
  flex-direction: column;
  padding: 0px;
  margin-top: 4px;
  resize: none;
}

.company_write_wrapper .phone_input {
  min-width: 230px;
}

.company_fax {
  margin-bottom: 38px;
}

/* 신규상담 등록 */
.work_option>div>input {
  width: 60%;
  min-width: 216px;
  height: 36px;
  padding: 12px 10px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 14px;
}

.member_phone {
  display: flex;
  justify-content: flex-end;
}

/* 
.write_content > div:not(.write_member_phone) > input {
  width: 60%;
  min-width: 216px;
  height: 34px;
} */

.write_member_phone>div:not(:first-child) {
  width: 60%;
  min-width: 216px;
}

.write_member_phone>div>input {
  width: 33%;
  height: 36px;
  padding: 12px 10px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 14px;
}

.write_member_phone>div>span {
  margin-left: 5px;
  margin-right: 5px;
  line-height: 36px;
}

.input_price::-webkit-outer-spin-button,
.input_price::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_price[type="number"] {
  -moz-appearance: textfield;
}

.write_cons_sum>span {
  width: 60%;
  min-width: 216px;
  height: 34px;
  padding: 7px 10px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 14px;
}

/* 유저 차트 */
.btn_user_table {
  height: 40px;
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_user_table>button {
  width: 72px;
  height: 34px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.approval_option>select {
  width: 102px;
  height: 34px;
  margin-left: 8px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  padding-left: 5px;
}

/* select 커스텀*/
.custom-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  -webkit-appearance: none;
  /* 크롬 및 사파리 브라우저에서 기본 스타일 제거 */
  -moz-appearance: none;
  /* 파이어폭스 브라우저에서 기본 스타일 제거 */
  appearance: none;
  /* 기본 스타일 제거 */
  position: relative;
}

.custom-select:focus {
  outline: none;
  border-color: #007bff;
}

.custom-select option[value=""] {
  color: #ccc;
}

.custom-select.invalid {
  border-color: red;
}

.custom-select>option {
  position: absolute;
  width: 100px;
}

.add_wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 34px;
  gap: 5px;
  margin-bottom: 10px;
}

.add_wrap input {
  height: 100%;
  width: 190px;
  font-size: 14px;
  padding: 12px 10px;
  border: 1px solid #cccccc;
  border-radius: 6px;
}

.add_wrap>button {
  height: 100%;
  font-size: 14px;
}

.role_wrap {
  margin-top: 20px;
}

.part_role__wrap table th {
  text-align: center;
  padding: 0 10px;
}

.part_role__wrap table td {
  text-align: center;
  padding: 10px 10px;
}

.part_role__wrap table td:first-child {
  width: 150px;
}

.part_role__wrap table td:last-child {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.part_role__wrap table button {
  height: 34px;
  padding: 0 15px;
  border-radius: 6px;
  font-size: 14px;
}

.part_role__wrap table input {
  height: 34px;
  border-radius: 6px;
  border: 1px solid #cccccc;
  text-align: center;
  width: 120px;
  font-size: 15px;
}

.write_bottom {
  position: absolute;
  top: 100%;
  right: 24px;
}

.write_bottom>button {
  width: 85px;
  height: 40px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  cursor: pointer;
  margin: 30px 0;
}

.write_bottom>button:not(:last-child) {
  margin-right: 8px;
}