@import "./styles/reset.css";
@import url("https://cdn.jsdelivr.net/gh/orioncatus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

body {
  margin: 0;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, system-ui,
    Roboto, "Helvetica Neue", "Segoe UI", "Noto Sans KR", "Malgun Gothic",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* max-width: 1920px; */
  min-width: 1300px;
  /* color: #262626; */
  overflow-y: hidden;

  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-color: rgb(250, 250, 251);
}

:root {
  --base-unit: 16px;
  --container: 1440px;
  --highlight-c: #104573;
  --highlight-cark-c: #55762c;
  --light-bg-c: #cedbf776;
  --text-c: #262626;
  --light-text-c: #a8a8a8;
  --light-txt: #6e6e6e;
  --light-bt-c: rgb(230, 235, 241);
  --border-c: #ebebeb;
  --warning-c: #ff5630;
  background-color: rgb(250, 250, 251);
}

button {
  font-family: "Pretendard", Pretendard, -apple-system, BlinkMacSystemFont,
    system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
    "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", sans-serif;
  width: fit-content;
  cursor: pointer;
}

input {
  padding: 0 10px;
  font-family: "Pretendard", Pretendard, -apple-system, BlinkMacSystemFont,
    system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
    "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", sans-serif;
}

textarea {
  padding: 0 10px;
  font-family: "Pretendard", Pretendard, -apple-system, BlinkMacSystemFont,
    system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
    "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", sans-serif;
}

.container {
  width: 100%;
  min-width: 1290px;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.con_pd {
  padding: var(--base-unit);
}

.con_top_pd {
  padding-top: var(--base-unit);
}

.con_top_mg {
  margin-top: var(--base-unit);
}

.border {
  border-radius: 8px;
  border: 1px solid rgb(230, 235, 241);
}

.filter_container {
  background: #fff;
}

.ag-root-wrapper {
  border-radius: 6px;
}

h1 {
  font-size: 32px;
  /* font-weight: 600; */
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

span {
}

select {
  padding: 0 8px;
}
