.mplan_content {
  width: 1080px;
  height: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  overflow-y: scroll;
}

.mplan_content>div>div>p {
  font-size: 20px;
}

.mplan_detail>input {
  width: 1032px;
  height: 238px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

.mplan_detail>p:not(:first-child) {
  margin-bottom: 10px;
  font-size: 18px;
}

.mplan_content_input {
  width: 1032px;
  height: 238px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

.overflow {
  height: fit-content;
}

.mplan_content_input>tbody>tr {
  width: 1032px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type="number"] {
  -moz-appearance: textfield;
}

.mplan_table {
  height: 260px;
}

.mplan_empty_tbody {
  position: relative;
  width: 1030px;
  height: 42px;
}

.complete_row {
  background-color: rgb(203, 203, 255);
}

.in_progress_row {
  background-color: rgb(255, 255, 196);
}

.mplan_empty {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.work_option>div>.check_radio {
  width: 60%;
  min-width: 216px;
  height: 34px;
  display: flex;
  align-items: center;
}

.check_radio>div {
  width: 120px;
}

.mplan_content_row {
  width: 1030px;
}

.custom-select {
  position: relative;
  cursor: pointer;
  width: 112px;
  height: 34px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 6px;
  font-size: 14px;
  color: #000;
}

.custom-select .selected-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.custom-select .selected-value span svg {
  line-height: 27px;
  vertical-align: bottom;
}

.custom-select-list {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  transform: translate(0%, 22%);
  z-index: 1;
  width: 112px;
  max-height: 200px;
  /* 최대 높이 설정 */
  overflow-y: auto;
  /* 스크롤바 추가 */
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.custom-select:focus-within .custom-select-list {
  display: block;
}

.custom-select-list .option {
  padding: 5px 8px;
}

.custom-select-list .selected {
  background-color: #cedbf776;
  color: #023373;
}

.custom-select-list .option:hover {
  background-color: #cedbf776;
  color: #023373;
}

.no-hover:hover {
  background-color: #fff;
  /* 기존에 설정된 hover 색상을 제거하거나 투명하게 설정 */
  cursor: default;
  /* 포인터 모양을 기본값으로 설정 */
}