.mainTop_wrapper {
  padding: 12px 25px;
  display: flex;
  flex-direction: column;
}

.mainTop_wrapper .manageBtn {
  height: 100%;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
}

.mainTop_wrapper .manageBtn:hover svg {
  fill: var(--highlight-c);
  opacity: 60%;
}

.mainTop_wrapper .manageBtn svg {
  fill: #262626;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  width: 90px;
}

#main_card {
  padding: 30px;
  min-height: 600px;
  background-color: rgb(255, 255, 255);
  color: rgb(38, 38, 38);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  position: relative;
  border: none rgb(230, 235, 241);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px;
}

.month_card>div {
  padding: 20px;
}

.table_header {
  height: 42px;
  padding: 10px;
  justify-content: space-between;
  align-items: flex-start;
  background: #f4f4f4;
  border-style: none;
  font-size: 14px;
  font-family: "Pretendard-Variable";
  font-weight: 600;
  text-align: left;
  top: 0px;
  position: sticky;
}

.table_header th {
  padding: 0 5px;
  text-align: center;
  border: 1px solid rgba(224, 224, 224, 1);
}

.table_header th:last-child {
  border-right: none;
}

.table_header th:first-child {
  border-left: none;
}

.empty_table {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.table_row>tr {
  height: 40px;
  font-family: "Pretendard-Variable";
  cursor: pointer;
  border-bottom: 1px solid #d8d8d8;
}

.table_row>tr>td {
  text-align: left;
  /* text-align: center; */
  font-size: 14px;
  padding: 0 5px;
}

.table_row>tr>td:not(:last-child) {
  border-right: 1px solid #d8d8d8;
}

.table_row>tr>td:first-child {
  /* padding-left: 10px; */
}

.table_row tr:hover {
  background-color: #f3ebff;
  /* hover 상태의 배경색 */
}

.table_row tr:active {
  background-color: #f3ebff;
  /* active(클릭) 상태의 배경색 */
}

.month_card>div>p {
  font-size: 20px;
  margin-bottom: 20px;
}

#month_plan_card {
  width: 100%;
  /* height: 118px; */
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  /* height: 180px; */
}

.title {
  font-size: 32px;
  color: rgb(38, 38, 38);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  position: relative;
  border: none rgb(230, 235, 241);
  border-radius: 4px;
  box-shadow: inherit;
  margin-bottom: 24px;
  background-color: inherit;
  background-image: none;
}

.menu {
  color: #8c8c8c;
  margin: 10px 0;
  font-size: 15px;
}

.sub_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sub_menu__title {
  display: flex;
  align-items: center;
  height: 50px;
  line-height: 50px;
  gap: 10px;
}

.sub_menu__title h3 {
  color: var(--highlight-c);
  font-weight: 600;
  vertical-align: bottom;
}

/* 
.sub_menu div {
  font-size: 26px;
  padding: 0 10px;
} */



.write_button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* width: 130px; */
  padding: 0 13px;
  height: 40px;
  border-radius: 6px;
  border: none;
  color: #fff;
  background-color: #104573;
  cursor: pointer;
  font-size: 15px;
  gap: 5px;
}

.cancel_button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 13px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #ccc;
  color: #000;
  background: var(--light-bt-c);
  cursor: pointer;
  font-size: 15px;
  gap: 5px;
}

.dialog__cancel_button {
  background-color: #104573;
  color: #fff;
  border: none;
  /* cursor: pointer; */

  border: 1px solid #ccc;
}

.dialog__confirm_button {
  background-color: #023373;
  color: #fff;
  border: none;
  cursor: pointer;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root {
  z-index: 1400;
}

.delete_button {
  background-color: "#fff";
  color: "tomato";
  border: "1px solid #ccc";
}