* {
  box-sizing: border-box;
}

/* 차량 일지 */
.car_option {
  width: 352px;
  /* height: 224px; */
  display: flex;
  flex-direction: column;
}

.car_option>div {
  display: flex;
  gap: 20px;
  align-items: center;
  min-height: 50px;
}

.car_option>.log_note {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
}

.car_option>div>.div_icon {
  width: 130px;
}

.car_option>div>input {
  height: 34px;
}

/* .car_option > div:not(:last-child) {
  margin-bottom: 4px;
} */

.car_option>div>span {
  width: 60%;
  min-width: 216px;
  height: 34px;
}

.car_option>div>input {
  width: 60%;
  min-width: 216px;
  padding: 10px 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.car_option>div>select {
  width: 60%;
  min-width: 216px;
  height: 34px;
  padding-left: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.input_distance::-webkit-outer-spin-button,
.input_distance::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_distance[type="number"] {
  -moz-appearance: textfield;
}

.check_personal {
  line-height: 50px;
  display: flex;
  align-items: center;
}

.check_personal>div {
  width: 200px;
  /* margin-right: 20px; */
}

/* 사용자 등록 */
.user_option>div {
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user_option>div>input {
  width: 60%;
  min-width: 216px;
  height: 34px;
  padding: 12px 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.user_option>div>input[type="password"]::placeholder {
  color: black;
}

.user_option>div>span {
  width: 60%;
  min-width: 216px;
}

.user_option>div>select {
  width: 60%;
  min-width: 216px;
  height: 34px;
  padding-left: 5px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.user_option>div>.phone_input {
  width: 60%;
  min-width: 216px;
}

.user_option>div:not(:last-child) {
  margin-bottom: 4px;
}

.user_option>.input_phone_number>text {
  width: 216px;
}

.input_password_change {
  width: 216px;
  height: 34px;
}

.input_password_change>input {
  width: 176px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.input_password_change>button {
  width: 38px;
  height: 34px;
  border-radius: 4px;
  border: none;
  margin-left: 2px;
  cursor: pointer;
}

.input_user_id::-webkit-outer-spin-button,
.input_user_id::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_user_id[type="number"] {
  -moz-appearance: textfield;
}

.check_admin {
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
}

.check_admin>input {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}