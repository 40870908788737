@import url(../../src/index.css);

.grid-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ag-root-wrapper {
  border: 1px solid #eee;
}

.ag-theme-alpine {
  flex-grow: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  --ag-alpine-active-color: #023373;
  --ag-selected-row-background-color: rgba(50, 123, 184, 0.3);
  --ag-font-family: "Pretendard", -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

.ag-theme-alpine .ag-header {
  color: #8b8b8b76;
}

.ag-theme-alpine .ag-header-cell-label {
  white-space: normal;
  /* 텍스트가 셀 너비를 초과하면 자동으로 줄 바꿈 */
}

.ag-theme-alpine .ag-header-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-theme-alpine .ag-row:hover {
  cursor: pointer;
}

/* .grid-container select {
  width: 60%;
  min-width: 216px;
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 14px;
  margin: 0 10px;
  } */

.resetBtn {
  height: 34px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ccc;
  background: var(--light-bg-c);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 14px;
}

.ag-theme-alpine .ag-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.custom__manage-menu {
  text-align: center;
  padding: 0;
}

.quarterColor {
  background-color: #bcd9ff34;
}

.quarterColor_odd {
  background-color: #bcd9ff20;
}

.halfColor {
  background-color: #fffbbc34;
}

.yearTotalColor {
  background-color: #bcd9ff34;
}

.row-red {
  background-color: #fa5b8a93;
}

.row-is_deleted {
  background-color: #e1839f47;
}

.row-green {
  background-color: rgb(220, 220, 255);
}

.row-yellow {
  background-color: rgb(251, 251, 186);
}

.row-complete {
  color: #aaa;
}

.progress-color {
  display: flex;
}

.progress-color>span {
  margin: 0 10px;
  font-size: small;
}

.plan_filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.plan_filter>div {
  display: flex;
  align-items: center;
}

.plan_filter input {
  width: 230px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0px 5px;
}

.filter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
  font-size: 14px;
}

.filter input {
  width: 230px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0px 5px;
}

.filter>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wordSearch_container {
  display: flex;
  align-items: center;
}

.wordSearch_container label {
  padding: 0 5px;
}

.dateSearch_content {
  display: flex;
  align-items: center;
}

.dateSearch_content .calendar_box {
  min-width: 315px;
}

.dateSearch_content input {
  width: fit-content;
  font-family: "Pretendard", -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 14px;
}

.term_btn_box {
  display: flex;
  align-items: center;
  gap: 1px;
}

.term_btn_box>button {
  padding: 0px 6px;
  border-radius: 6px;
  border: 1px solid #ccc;
  height: 34px;
  min-width: 50px;
  cursor: pointer;
}

.term_btn_box>button.selected {
  background-color: #104573;
  color: white;
  border: 1px solid #104573;
  font-weight: bold;
}

.carSelect_container {
  display: flex;
  align-items: center;
}

.carSelect_container button {
  width: 110px;
  height: 34px;
  padding: 0px 10px;
  border-radius: 6px;
  background: var(--light-bg-c);
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.carSelect_container button:disabled {
  background: white;
  color: #aaa;
  cursor: not-allowed;
}

.carSelect_container select {
  width: 125px;
  height: 34px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 14px;
}

.carSelect_container label {
  margin: 0 10px;
}

.viewSize_btn_box {
  display: flex;
  align-items: center;
  gap: 1px;
}

.viewSize_btn_box label {
  margin: 0 5px;
}

.viewSize_btn {
  width: 50px;
  height: 34px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.viewSize_btn.selected {
  background-color: #104573;
  color: white;
  border: 1px solid #104573;
  font-weight: bold;
}

.approval_checkbox {
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  accent-color: #104573;
}

.approval_container button {
  padding: 0px 10px;
  border: 1px solid #ccc;
  box-shadow: none;
  border-radius: 6px;
  height: 34px;
  width: 100%;
  color: #000;
  font-weight: 400;
  font-size: 13px;
  background-color: var(--light-bg-c);
}

.comp_name {
  cursor: pointer;
}

.comp_name:hover {
  color: #104573;
  font-weight: bold;
  text-decoration: underline;
}

.preview_table {
  width: 100%;
  /* 테이블 너비 설정 */
  min-width: 1600px;
  min-height: 200px;
  text-align: center;
  font-size: 14px;
}

.preview_table thead {
  background-color: #f0f0f0;
  font-weight: 500;
}

.preview_table tr {
  max-height: 22px;
}

.preview_table th,
.preview_table td {
  padding: 1px 3px;
  border: 1px solid #ccc;
}

.preview_table td:nth-child(1),
.preview_table th:nth-child(1),
.preview_table td:nth-child(2),
.preview_table th:nth-child(2) {
  max-width: 70px;
}

.preview_table td:nth-child(3),
.preview_table th:nth-child(3) {
  width: 120px;
  max-width: 150px;
  text-overflow: ellipsis;
  /* 텍스트가 넘칠 경우 ... 표시 */
  overflow: hidden;
  /* 넘치는 내용 숨기기 */
  white-space: nowrap;
  /* 줄 바꿈 방지 */
}

.preview_table td:nth-child(3):after {
  content: attr(data-text);
  /* 데이터 속성에서 텍스트 가져오기 */
  display: inline-block;
  width: 0;
  /* 너비를 0으로 설정하여 숨김 */
  overflow: hidden;
  /* 넘치는 내용 숨기기 */
  white-space: nowrap;
  /* 줄 바꿈 방지 */
}

.preview_table tbody {
  overflow-y: scroll;
  font-size: 13px;
}

.uploadBtn_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.uploadBtn_wrapper input {
  width: 450px;
  font-size: 14px;
  cursor: pointer;
  line-height: 36px;
  font-weight: 500;
}

.upload_input::-webkit-file-upload-button {
  display: none;
}

.noData_box {
  text-align: center;
  color: #1976d2;
  font-weight: normal;
  border: 2px solid #1976d2;
  background-color: #c8e0ff85;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 90%;
  padding: 100px;
}

.year_select {
  font-family: "Pretendard", -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  position: relative;
  cursor: pointer;
  width: 112px;
  height: 36px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 6px;
  font-size: 16px;
  color: #000;
}

.custom-select__user {
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 6px;
  height: 34px;
  padding: 0 10px;
}