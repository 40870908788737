/* header */
* {
  box-sizing: border-box;
}

#sideList > div {
  margin-bottom: 4px;
}

#listBtn {
  height: 48px;
  margin-right: 5px;
  margin-left: 10px;
  border-radius: 6px;
}

/* sideList button txt */
#listSubBtn .MuiListItemText-primary {
  font-size: 15px;
}

.listHover:hover {
  background-color: #cedbf776;
  color: #023373;
}

/* MuiListItemText-primary는 Material-UI에서 ListItemText 컴포넌트의 기본 텍스트 스타일에 사용되는 클래스 */
.listHover:hover .MuiListItemText-primary {
  font-weight: 600;
}

.listHover.selected {
  background-color: #cedbf776 !important;
  color: #023373 !important;
}

.listHover.selected > div {
  color: #023373 !important;
}

.listHover.selected .MuiListItemText-primary {
  font-weight: 600 !important;
}

.listHover:hover {
  background-color: #cedbf776 !important;
  color: #023373;
  font-weight: 600;
  /* transition: background-color 0.3s color 0.3s; */
}

#listSubBtn {
  height: 40px;
  margin-left: 20px;
  padding-left: 42px;
  color: #666;
  border-radius: 6px;
  background-color: inherit;
  transition: transform 0.1s, background-color 0.2s;
}
#listSubBtn:nth-child(1) div span {
  font-weight: 600;
}
#listSubBtn:nth-child(2) div span {
  font-weight: 600;
}

#listSubBtn:hover:not(.selected) {
  transform: translateX(5px);
  transition: all 0.5s;
  color: #023373;
  font-weight: 600;
}

#listSubBtn.selected {
  transform: translateX(5px);
}

#listSubBtn.selected span {
  color: #023373;
  font-weight: 600;
}

#listIcon {
  min-width: 40px;
  color: #000;
}

#panel1bh-header {
  font-size: 15px;
  border-radius: 6px;
  margin: 0px 5px 0 10px;
}

#nested-list-subheader {
  height: 40px;
  color: #a1a1a1;
  font-size: 12px;
  padding-left: 25px;
}

/* scroll.css */
::-webkit-scrollbar {
  display: block;
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #e3e2e2;
  border-radius: 10px;
}

.css-1tn6p2j-MuiDrawer-docked .MuiDrawer-paper {
  overflow-y: scroll !important;
}

/* accordion.css */
.accordionSummary {
  color: inherit;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accordionSummary.expanded {
  background-color: #cedbf776;
  color: #023373;
  font-weight: 600;
}

.accordionSummary .MuiListItemIcon-root,
.accordionSummary .MuiSvgIcon-root {
  color: inherit;
}

.accordionSummary.expanded .MuiListItemIcon-root,
.accordionSummary.expanded .MuiSvgIcon-root {
  color: #023373;
}

#accordionList {
  padding: 5px 16px 16px;
}

.btn_setting {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 12px 10px; */
  margin-left: 8px;
  margin-right: 4px;
  border: 1px solid #d9d9d9;
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: pointer;
}

.btn_logout {
  height: 32px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  border-radius: 32px;
  border: 1px solid #d9d9d9;
  background-color: transparent;
  cursor: pointer;
}

.btn_setting:hover,
.btn_logout:hover {
  box-shadow: 1px 2px 1px 0px rgba(0, 0, 0, 0.5);
}
