.wrap_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 16px;
}

.login_top img {
  max-width: 100%;
  height: auto;
  margin-bottom: 41px;
}

.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 41px;
}

.login_form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#form_id input {
  background-image: url("assets/account_circle.svg");
  background-repeat: no-repeat;
  background-position: 4px center;
  padding-left: 30px;
}

#form_pwd input {
  background-image: url("assets/lock.svg");
  background-repeat: no-repeat;
  background-position: 4px center;
  padding-left: 28px;
}

.login_form input {
  width: 320px;
  height: 40px;
  padding: 11px 4px;
  font-size: 14px;
  margin-bottom: 8px;
  border-style: none;
  border-bottom: 1px solid #ccc;
}

.login_form input:focus {
  outline: none;
  border-bottom-color: #104573;
}

.login_form button {
  width: 320px;
  height: 51px;
  padding: 19px 132px;
  border-radius: 6px;
  margin-top: 8px;
  opacity: 0.5;
  background-color: #626262;
  border-style: none;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 15px;
  cursor: not-allowed;
}

.login_form button:enabled {
  background-color: #104573;
  opacity: 1;
  cursor: pointer;
}

.login_bottom {
  width: 320px;
  line-height: 30px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.login_bottom .id_save {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.id_save label {
  cursor: pointer;
}

.login_bottom input[type="checkbox"] {
  margin: 0;
  width: 20px;
  accent-color: var(--highlight-c);
  height: 20px;
}

.login_bottom #check:checked + label {
  color: var(--highlight-c);
  font-weight: 700;
}

.input_ip_address {
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
}

.input_ip_address > button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  align-items: center;
  border: 1.3px solid #ccc;
  padding: 0;
  display: flex;
  justify-content: center;
  background: none;
  cursor: pointer;
}

.input_ip_address > button > svg {
  margin: 0;
  padding: 0;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ip_set {
  width: 320px;
  text-align: end;
  position: absolute;
  top: 100%;
  margin-top: 10px;
  display: flex;
  align-items: end;
  justify-content: end;
}

.ip_set input {
  width: 130px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 4px;
  line-height: 17px;
}

.ip_set button {
  width: 45px;
  height: 27px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
