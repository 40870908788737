.comp_detail_header {
  width: 1030px;
  height: 48px;
  padding-bottom: 16px;
  font-size: 24px;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.comp_detail_header button {
  border-radius: 32px;
  background-color: #4e20a5;
  color: white;
  width: 82px;
  height: 32px;
  border: none;
  cursor: pointer;
}

.comp_detail_info {
  position: relative;
  width: 720px;
  height: 134px;
  display: flex;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 8px rgb(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.comp_update_btn {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.comp_update_btn > button {
  margin-right: 8px;
  margin-bottom: 8px;
  border: none;
  background-color: #fff;
  color: #2b8dd3;
}

.comp_categories {
  width: 128px;
  height: 134px;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: #f4f4f4;
  font-weight: 600;
  justify-content: space-around;
}

.comp_categories > span {
  padding: 3px;
}

.comp_info {
  width: 80%;
  height: 134px;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 5px 0;
}

.comp_info > span {
  padding: 3px;
  padding-left: 0px;
  border-bottom: 1px solid #ccc;
}

.comp_info > span:last-child {
  border: none;
}

.member_table .sales_table {
  align-items: center;
  font-family: "Pretendard-Variable";
}

/* 
.scrollable_member_table::-webkit-scrollbar {
  display: none;
} */

.scrollable_member_table {
  height: 255px;
  /* -ms-overflow-style: none; */
  /* scrollbar-width: none; */
  overflow-y: scroll;
}

/* 
.scrollable_sales_table::-webkit-scrollbar {
  display: none;
} */

.scrollable_sales_table {
  height: 255px;
  /* -ms-overflow-style: none; */
  /* scrollbar-width: none; */
  overflow-y: scroll;
}

.member_info_title {
  margin: 8px 0;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales_info_title {
  margin: 8px 0;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales_table th:nth-child(3) {
  line-height: 42px;
  padding: 0 0 0 5px;
}
