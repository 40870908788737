@import url(../../src/index.css);
/* userInfo style*/
.user_info_wrapper {
  /* width: 1080px; */
  padding: 12px 25px;
  display: flex;
  flex-direction: column;
  background: #fff;
}

/* .user_info_title {
    height: 85px;
    font-size: 28px;
} */

.user_info_data {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 17px;
}

.user_info_data > div {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 100px;
}

.user_info_data > div label {
  width: 200px;
  line-height: 34px;
  opacity: 60%;
  /* margin-right: 100px; */
}

.user_info_data > div p {
  margin: 0;
  width: 250px;
  padding: 0 10px;
  line-height: 40px;
  letter-spacing: 1px;
}

.user_info_data > div select {
  width: 280px;
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 0 10px;
  color: #262626;
}
/* 
.user_info_data > div input {
  width: 280px;
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 0 13px;
  accent-color: #104573;
  font-size: 16px;
  margin: 0;
} */
.user_info_data > div > div {
  display: flex;
}
.user_info_data > div > input {
  width: 280px;
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 0 13px;
  accent-color: #104573;
  font-size: 16px;
  margin: 0;
  color: var(--text-c);
}

.user_info_data > div > div input[type="checkbox"] {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  accent-color: #104573;
  border: 1px solid #ccc;
  margin: 0;
}
.user_info_data > div > div > span {
  color: var(--warning-c);
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
}

.user_phone_form > .phone_input {
  width: 280px;
}

.user_phone_form > .phone_input input {
  width: 75px;
  text-align: center;
  font-size: 16px;
  color: var(--text-c);
}

/* .user_info_data button {
  width: 116px;
  height: 40px;
  border-radius: 6px;
  border: none;
  color: #fff;
  background-color: #104573;
  cursor: pointer;
  font-size: 15px;
} */

/* user_info_bottom */
.user_info_data .user_info_bottom {
  display: flex;
  justify-content: end;
  margin-top: 24px;
  gap: 20px;
}
.user_info_data .user_info_bottom button {
  width: 90px;
}

/* 
.user_info_bottom > button.edit {
  position: absolute;
  right: 0;
  bottom: 0;
}

.user_info_bottom > button.save {
  position: absolute;
  right: 0;
  bottom: 0;
}

.user_info_bottom > button.cancel {
  position: absolute;
  right: 100px;
  color: #000;
  background-color: #fff;
} */

/* .phone_input > span {
  margin-left: 5px;
  margin-right: 5px;
} */

.btn_pwd_change {
  cursor: pointer;
}

.change_pwd_wrapper {
  padding: 20px;
}

.title_change_pwd {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 600;
}

.input_change_pwd {
  height: 126px;
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.input_change_pwd > div {
  display: flex;
  height: 34px;
  align-items: center;
  justify-content: space-between;
}

.input_change_pwd > div:last-child {
  justify-content: center;
}

.input_change_pwd > div > input {
  width: 270px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.btn_delete_pwd {
  position: relative;
  width: 100%;
  height: 34px;
  margin-top: 18px;
}

.btn_delete_pwd > button {
  width: 73px;
  height: 34px;
  border-radius: 6px;
  cursor: pointer;
}

.confirm_delete {
  position: absolute;
  right: 80px;
  border: 1px solid #ccc;
}

.delete_cancel {
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  background-color: #104573;
}

.btn_change_pwd {
  /* width: 446px; */
  height: 40px;
  gap: 10px;
  display: flex;
  justify-content: flex-end;
}

/*
.change_cancel {
  position: absolute;
  right: 120px;
  bottom: 0;
  border: 1px solid #ccc;
}

.change_save {
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  color: #fff;
  background-color: #104573;
} */
